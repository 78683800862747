const imagesContext = require.context('./img', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

require('bootstrap');
require('simplebar');
require('smooth-scroll');

// any CSS you import will output into a single css file (app.css in this case)
import './scss/index.scss';

import './js/volt';
import './js/editor';

document.addEventListener('scroll', function(e) {
    var element = document.getElementById("navbar-main");
    if (window.pageYOffset > 0) {
        element.classList.add("scrolled");
        element.classList.remove("text-white");
        element.classList.add("text-secondary");
    }
    if (window.pageYOffset === 0) {
        element.classList.remove("scrolled");
        element.classList.add("text-white");
    }
});
